import useCartService from '../services/cart'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import useOrderService from '../services/order'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useToast from '../util/promiseToast'
import { calculateItemPrice } from '../util/convert'
import useDiscountCodeService from '../services/discountCode'
import useProductService from '../services/product'
const initialState = {
  localCart: undefined,
  content: [],
  deliveryMethod: undefined,
  courrierAddress: undefined,
  pickupPointData: undefined,
  deliveryPhone: undefined,
  paid: false,
  animate: false,
  animateTimeout: undefined,
  paymentStatus: undefined,
  paymentReference: undefined,
  businessComments: { name: undefined, regNo: undefined, address: undefined },
  generalComments: undefined,
  deliveryComments: undefined,
  availableLoyaltyMoney: undefined,
  usingLoyaltyMoney: true,
  discountCode: undefined,
  discountCodePercent: 0,
  email: undefined,
}

const updateLocalCart = (state) => {
  const { animate, animateTimeout, ...withoutAnimation } = state
  window.localStorage.setItem('localCart', JSON.stringify(withoutAnimation))
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart(state, action) {
      return initialState
    },
    setContent(state, action) {
      state.content = action.payload
    },
    startAnimate(state, action) {
      state.animate = true
    },
    setAnimateTimeout(state, action) {
      state.animateTimeout = action.payload
    },
    stopAnimate(state, action) {
      state.animate = false
    },
    clearAnimationTimeout(state, action) {
      clearTimeout(state.animateTimeout)
    },
    setCart(state, action) {
      return action.payload
    },
    setDeliveryMethod(state, action) {
      state.deliveryMethod = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setCourrierAddress(state, action) {
      state.courrierAddress = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setPickupPointData(state, action) {
      state.pickupPointData = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setDeliveryPhone(state, action) {
      state.deliveryPhone = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setPaid(state, action) {
      state.paid = action.payload
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload
    },
    setPaymentReference(state, action) {
      state.paymentReference = action.payload
    },
    setBusinessComments(state, action) {
      state.businessComments = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setGeneralComments(state, action) {
      state.generalComments = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setDeliveryComments(state, action) {
      state.deliveryComments = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setUsingLoyaltyMoney(state, action) {
      state.usingLoyaltyMoney = action.payload
    },
    setDiscountCode(state, action) {
      state.discountCode = action.payload.code
      state.discountCodePercent = action.payload.percent
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    putInCart(state, action) {
      let item = action.payload
      let existingItem
      if (
        (existingItem = state.content.find(
          (p) => p.productId === item.productId
        ))
      ) {
        existingItem.quantity += Number(item.quantity)
        if (existingItem.quantity <= 0) {
          state.content = state.content.filter(
            (i) => !i.productId === item.productId
          )
        }
      } else {
        if (Number(item.quantity) <= 0) {
        } else {
          state.content = state.content.concat(item)
        }
      }
      updateLocalCart(state)
    },
    saveToLocalStorage(state, action) {
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
    setEmail(state, action) {
      state.email = action.payload
      if (state.localCart) {
        updateLocalCart(state)
      }
    },
  },
})
const selectContent = (state) => state.cart.content
const selectDeliveryMethod = (state) => state.cart.deliveryMethod
const selectBusinessComments = (state) => state.cart.businessComments
const selectAvailableLoyaltyMoney = (state) => state.cart.availableLoyaltyMoney
const selectUsingLoyaltyMoney = (state) => state.cart.usingLoyaltyMoney
const selectDiscountCode = (state) => state.cart.discountCode
const selectDiscountCodePercent = (state) => state.cart.discountCodePercent

const selectCartSubtotal = createSelector([selectContent], (content) =>
  content
    .map((i) => calculateItemPrice(i))
    .reduce((acc, cur) => {
      return acc + cur
    }, 0)
)
const selectReceivedLoyaltyMoney = createSelector(
  [selectCartSubtotal],
  (subtotal) => {
    return subtotal * 0.01
  }
)

const deliveryMethods = ['bakery', 'pickupPoint', 'courrier']

const deliveryCosts = {
  bakery: 0,
  pickupPoint: 330,
  courrier: 599,
}
const thresholds = {
  bakery: undefined,
  pickupPoint: 2500,
  courrier: undefined,
}

const selectDiscountCodeDiscount = createSelector(
  [selectCartSubtotal, selectDiscountCodePercent, selectDiscountCode],
  (total, discountCodePercent, discountCode) => {
    if (!discountCode) {
      return 0
    }
    return total * (discountCodePercent / 100)
  }
)

const selectDeliveryCost = createSelector(
  [selectCartSubtotal, selectDeliveryMethod],
  (total, deliveryMethod) => {
    if (!deliveryMethod) {
      return undefined
    }
    if (thresholds[deliveryMethod]) {
      if (total >= thresholds[deliveryMethod]) {
        return 0
      } else {
        return deliveryCosts[deliveryMethod]
      }
    } else {
      return deliveryCosts[deliveryMethod]
    }
  }
)

const selectAllDeliveryCosts = createSelector([selectCartSubtotal], (total) => {
  return Object.fromEntries(
    deliveryMethods.map((c) => {
      if (thresholds[c]) {
        if (total >= thresholds[c]) {
          return [c, 0]
        } else {
          return [c, deliveryCosts[c]]
        }
      } else {
        return [c, deliveryCosts[c]]
      }
    })
  )
})

const selectCartTotal = createSelector(
  [
    selectContent,
    selectDeliveryCost,
    selectAvailableLoyaltyMoney,
    selectUsingLoyaltyMoney,
    selectDiscountCodeDiscount,
  ],
  (
    content,
    deliveryCost,
    availableLoyaltyMoney,
    usingLoyaltyMoney,
    discountCodeDiscount
  ) => {
    return (
      content
        .map((i) => calculateItemPrice(i))
        .reduce((acc, cur) => {
          return acc + cur
        }, 0) -
      discountCodeDiscount +
      deliveryCost -
      (usingLoyaltyMoney ? availableLoyaltyMoney : 0)
    )
  }
)

const selectIsBusiness = createSelector(
  [selectBusinessComments],
  (businessComments) =>
    businessComments &&
    businessComments.name &&
    businessComments.address &&
    businessComments.regNo
)
export {
  selectCartTotal,
  selectCartSubtotal,
  selectDeliveryCost,
  selectIsBusiness,
  selectAllDeliveryCosts,
  deliveryCosts,
  selectReceivedLoyaltyMoney,
  selectDiscountCodeDiscount,
}

export const {
  setContent,
  setCart,
  setCourrierAddress,
  setDeliveryMethod,
  setDeliveryPhone,
  setPickupPointData,
  setPaid,
  clearCart,
  startAnimate,
  setAnimateTimeout,
  stopAnimate,
  clearAnimationTimeout,
  setPaymentStatus,
  setPaymentReference,
  setBusinessComments,
  setDeliveryComments,
  setGeneralComments,
  setUsingLoyaltyMoney,
  setDiscountCode,
  setDiscountCodePercent,
  putInCart,
  saveToLocalStorage,
  setEmail,
} = cartSlice.actions

export const useCartServiceDispatch = () => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const discountCodeService = useDiscountCodeService()
  const cartService = useCartService()
  const productService = useProductService()
  const { showErrorToastNoPromise } = useToast()

  const initLocalCart = () => {
    return async (dispatch) => {
      dispatch(setCart({ localCart: true, content: [] }))
      dispatch(saveToLocalStorage())
    }
  }
  const loadLocalCart = (localCart) => {
    return async (dispatch) => {
      if (localCart.content && localCart.content.length > 0) {
        const products = await productService.getMultiple({
          ids: localCart.content.map((i) => i.productId),
        })
        const newContents = products.map((p) => {
          return {
            quantity: localCart.content.find((i) => i.productId === p.id)
              .quantity,
            product: p,
            productId: p.id,
          }
        })
        localCart.content = newContents
      }
      if (localCart.discountCode) {
        try {
          const response = await discountCodeService.checkDiscountCode(
            localCart.discountCode
          )
          if (response && response.discountCodePercentage) {
            localCart.discountCodePercent = response.discountCodePercentage
          }
        } catch (e) {
          localCart.discountCode = undefined
          localCart.discountCodePercent = undefined
        }
      }

      dispatch(setCart(localCart))
    }
  }
  const loadCart = () => {
    return async (dispatch) => {
      cartService
        .getCart()
        .then((response) => dispatch(setCart(response.data)))
        .catch((error) => {
          console.log(error.response.data.error)
          showErrorToastNoPromise(error)
        })
    }
  }
  const updateEmail = (email) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setEmail(email))
      }
    }
  }

  const addItem = (item) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(putInCart(item))
        dispatch(animateCart())
      } else {
        cartService.addToCart(item).then((response) => {
          dispatch(setContent(response.data.content))
          dispatch(animateCart())
        })
      }
    }
  }

  const removeItem = (item) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(putInCart({ productId: item.id, quantity: -999999999999 }))
        dispatch(animateCart())
      } else {
        cartService.removeFromCart(item).then((response) => {
          dispatch(setContent(response.data.content))
          dispatch(animateCart())
        })
      }
    }
  }

  const animateCart = () => {
    return async (dispatch) => {
      dispatch(clearAnimationTimeout())
      dispatch(startAnimate())
      dispatch(
        setAnimateTimeout(
          setTimeout(() => {
            dispatch(stopAnimate())
          }, 700)
        )
      )
    }
  }

  const changeQuantityOfItem = (item) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(putInCart(item))
        dispatch(animateCart())
      } else {
        cartService.changeQuantity(item).then((response) => {
          dispatch(setContent(response.data.content))
          dispatch(animateCart())
        })
      }
    }
  }
  const changeDeliveryMethod = (method) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setDeliveryMethod(method))
      } else {
        cartService
          .changeDeliveryMethod(method)
          .then((response) => {
            dispatch(setDeliveryMethod(response.data.deliveryMethod))
          })
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeCourrierAddress = (address) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setCourrierAddress(address))
      } else {
        cartService
          .changeCourrierAddress(address)
          .then((response) =>
            dispatch(setCourrierAddress(response.data.courrierAddress))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changePickupPointData = (data) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setPickupPointData(data))
      } else {
        cartService
          .changePickupPointData(data)
          .then((response) =>
            dispatch(setPickupPointData(response.data.pickupPointData))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeDeliveryPhone = (phone) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setDeliveryPhone(phone))
      } else {
        cartService
          .changeDeliveryPhone(phone)
          .then((response) =>
            dispatch(setDeliveryPhone(response.data.deliveryPhone))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeBusinessComments = (comments) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setBusinessComments(comments))
      } else {
        cartService
          .changeBusinessComments(comments)
          .then((response) =>
            dispatch(setBusinessComments(response.data.businessComments))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeGeneralComments = (comments) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setGeneralComments(comments))
      } else {
        cartService
          .changeGeneralComments(comments)
          .then((response) =>
            dispatch(setGeneralComments(response.data.generalComments))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeDeliveryComments = (comments) => {
    return async (dispatch, getState) => {
      const {
        cart: { localCart },
      } = getState()
      if (localCart) {
        dispatch(setDeliveryComments(comments))
      } else {
        cartService
          .changeDeliveryComments(comments)
          .then((response) =>
            dispatch(setDeliveryComments(response.data.deliveryComments))
          )
          .catch((error) => {
            console.log(error.response.data.error)
            showErrorToastNoPromise(error)
          })
      }
    }
  }
  const changeUsingLoyaltyMoney = (usingLoyaltyMoney) => {
    return async (dispatch) => {
      cartService
        .changeUsingLoyaltyMoney(usingLoyaltyMoney)
        .then((response) =>
          dispatch(setUsingLoyaltyMoney(response.data.usingLoyaltyMoney))
        )
        .catch((error) => {
          console.log(error.response.data.error)
          showErrorToastNoPromise(error)
        })
    }
  }

  const placeOrder = () => {
    return async (dispatch, getState) => {
      const { cart } = getState()
      if (cart.localCart) {
        cartService.placeOrderWithoutAccount(cart).then((response) => {
          window.location.href = response.data.paymentLink
          dispatch(setPaymentReference(response.data.paymentReference))
        })
      } else {
        cartService
          .placeOrder()
          .then((response) => {
            window.location.href = response.data.paymentLink
            dispatch(setPaymentReference(response.data.paymentReference))
          })
          .catch((error) => {
            console.log(error.response.data.error)
            dispatch(loadCart())
          })
      }
    }
  }
  const invoice = () => {
    return async (dispatch, getState) => {
      const { cart } = getState()
      if (cart.localCart) {
        cartService.invoiceWithoutAccount(cart).then((response) => {
          toast.success(lang.order_invoiced)
          dispatch(initLocalCart())
        })
      } else {
        cartService
          .invoice()
          .then((response) => {
            dispatch(setPaymentStatus('invoiced'))
            dispatch(loadCart())
          })
          .catch((error) => {
            console.log(error.response.data.error)
          })
      }
    }
  }
  const updatePaymentStatus = (paymentReference) => {
    return async (dispatch) => {
      cartService.getPaymentStatus(paymentReference).then((response) => {
        console.log(response.data)
        dispatch(setPaymentStatus(response.data.paymentStatus))
      })
    }
  }
  const tryDiscountCode = (code) => {
    return async (dispatch) => {
      discountCodeService
        .checkDiscountCode(code)
        .then((data) => {
          dispatch(
            setDiscountCode({
              code,
              percent: data.discountCodePercentage,
            })
          )
          toast.success(lang.toast_discount_code_activated)
        })
        .catch((error) => {
          showErrorToastNoPromise(error)
        })
    }
  }

  return {
    loadCart,
    addItem,
    removeItem,
    changeQuantityOfItem,
    changeCourrierAddress,
    changeDeliveryPhone,
    changePickupPointData,
    changeDeliveryMethod,
    placeOrder,
    updatePaymentStatus,
    changeBusinessComments,
    changeDeliveryComments,
    changeGeneralComments,
    changeUsingLoyaltyMoney,
    invoice,
    tryDiscountCode,
    loadLocalCart,
    initLocalCart,
    updateEmail,
  }
}

export default cartSlice.reducer
