import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import UserContext from '../../contexts/userContext'
import { useContext, useEffect } from 'react'

const ManagementPage = () => {
  const lang = useSelector((state) => state.lang[state.lang.selectedLang])
  const { pathname } = useLocation()
  const [user, setUser] = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !user.admin) {
      if (user.managementRights?.banner) {
        navigate('banners')
      }
    }
  }, [user])

  return (
    <div>
      {user && (
        <div
          className="header sub-header card"
          style={{ top: 'var(--header-height)' }}>
          {user.admin && (
            <NavLink
              className="tab"
              to="products/complete">
              {lang.products}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="categories"
              onClick={(event) => {
                event.preventDefault()
                navigate('categories/complete')
              }}>
              {lang.categories}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="new_product">
              {lang.new_product}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="new_category">
              {lang.new_category}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="sorting">
              {lang.sorting}
            </NavLink>
          )}
          {(user.admin || user.managementRights?.banner) && (
            <NavLink
              className="tab"
              to="banners">
              {lang.banner}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="settings">
              {lang.settings}
            </NavLink>
          )}
          {user.admin && (
            <NavLink
              className="tab"
              to="discount_codes">
              {lang.discount_codes}
            </NavLink>
          )}
        </div>
      )}
      <Outlet />
    </div>
  )
}
export default ManagementPage
