const lv = {
  products: 'Produkti',
  about: 'Par mums',
  contact: 'Kontakti',
  sign_in: 'Ienākt',
  sign_up: 'Reģistrēties',
  new_product: 'Jauns produkts',
  management: 'Pārvaldīt',
  categories: 'Kategorijas',
  sign_out: 'Iziet',
  sum: 'Kopā',
  paid_delivery: 'Piegāde',
  under: 'zem €',
  total: 'Pavisam',
  order: 'Pasūtīt',
  city: 'Pilsēta / Pagasts / Ciems',
  street: 'Iela / Māja',
  house: 'Mājas nr.',
  apt: 'Dzīvoklis',
  name: 'Vārds',
  surname: 'Uzvārds',
  phone: 'Tālrunis',
  add_new_address: 'Pievienot adresi',
  proceed_to_payment: 'Uz apmaksu',
  contact_us: 'Sazinies ar mums',
  email: 'E-pasts',
  message: 'Vēstule',
  send_message: 'Nosūtīt',
  buy: 'Pirkt',
  add_to_cart: 'Pievienot grozam',
  nutritional_info: 'Uzturvērtība',
  g_contains: '100g produkta satur',
  energy_content: 'Enerģētiskā vērtība',
  fat: 'Tauki',
  of_which_saturated_fat: 'tostarp piesātinātās taukskābes',
  carbohydrates: 'Ogļhidrāti',
  of_which_sugars: 'tostarp cukuri',
  fiber: 'Šķiedrvielas',
  protein: 'Olbaltumvielas',
  salt: 'Sāls',
  EAN_code: 'EAN kods',
  about_us: 'Par mums',
  values: 'Vērtības kuras godājam',
  nature: 'Daba',
  people: 'Cilvēks',
  quality: 'Kvalitāte',
  how_products_are_made: 'KĀ TOP MAIZNĪCAS FLORA PRODUKTI',
  product: 'Produkts',
  category: 'Kategorija',
  select_existing_categories: 'Izvēlēties no esošām kategorijām',
  select_existing_products: 'Izvēlēties no esošiem produktiem',
  add_category: 'Pievienot kategoriju',
  add_new_category: 'Izveidot jaunu kategoriju',
  product_name: 'Nosaukums',
  image_url: 'Saite uz attēlu',
  cancel: 'Atcelt',
  add: 'Pievienot',
  or: 'vai',
  add_new_product: 'Izveidot jaunu produktu',
  add_product: 'Pievienot produktu',
  description: 'Apraksts',
  ingredients: 'Sastāvdaļas',
  weight: 'Svars',
  in_grams: 'gramos',
  price: 'Cena ',
  in_cents: 'centos',
  add_to_all: "Pievienot 'all' kategorijai",
  add_to_new: "Pievienot 'new' kategorijai",
  accept_order: 'Pieņemt pasūtījumu?',
  accept: 'Pieņemt',
  refuse: 'Noraidīt',
  ready_for_delivery: 'Gatavs piegādāšanai?',
  ready: 'Gatavs',
  out_for_delivery: 'Nosūtīt?',
  deliver: 'Sūtīt',
  delivery_completed: 'Piegādāts?',
  completed: 'Piegādāts',
  order_status: {
    placed: 'Pasūtīts',
    ready_for_pickup: 'Saņemams',
    ready_for_delivery: 'Piegādājams',
    waiting_for_courrier: 'Gaida kurjeru',
    delivering: 'Tiek piegādāts',
    completed: 'Pabeigts',
    invoiced: 'Gaida apmaksu',
    refunded: 'Reversēts',
    dropped_in_pickup_point: 'Nodots paku skapī',
    picked_up_by_courrier: 'Nodots kurjeram',
    delivered_to_pickup_point: 'Piegādāts paku skapī',
  },
  last_modified_by: 'Pēdējoreiz mainījis',
  confirm_delete_product: 'Vai tiešām dzēst šo produktu?',
  confirm_save_changes: 'Vai tiešām saglabāt šīs izmaiņas?',
  save_changes: 'Saglabāt izmaiņas',
  discard_changes: 'Atcelt izmaiņas',
  edit_product: 'Rediģēt produktu',
  delete_product: 'Dzēst produktu',
  empty_cart: 'Pirkumu grozs ir tukšs.',
  orders: 'Pasūtījumi',
  username: 'Lietotājvārds',
  password: 'Parole',
  about_text_top:
    'Stāsts par mums ir himna roku darbam un gadu gaitā uzkrātām zināšanām, kā radīt kvalitatīvus un veselīgus gardumus. Tā ir himna Latvijas zemei, kas ik gadu vēlīgi pieņem zeltainos graudus un dāsni apbalvo mūs ar ražu. Tas ir stāsts par augsta līmeņa profesionāļiem, kas pašaizliedzīgi strādā, lai ikviens varētu celt savā galdā vislabāko – gan Latvijā, gan tālu pāri robežām. Stāsts par mums ir arī stāsts par skatu nākotnē un lieliem mērķiem.',
  about_text: [
    {
      title: 'MODERNA UN JAUDĪGA RAŽOTNE',
      text: 'Mēs lepojamies ar mūsdienīgu Eiropas Savienības līmenim atbilstošu ražotni, kurā ik gadu tiek saražotas ap 1000 tonnu produkcijas – maize, cepumi, piparkūkas un citi konditorejas izstrādājumi. ',
    },
    {
      title: 'LIELĀKAIS DARBA DEVĒJS NOVADĀ',
      text: 'Šobrīd uzņēmumā strādā 70 darbinieki – un mūsu skaits arvien pieaug. Mēs esam atvērta, zinātkāra un draudzīga komanda, ko veido arī ģimenes, turklāt dažiem šī ir bijusi vienīgā darba vieta. ',
    },
    {
      title: 'TĀLREDZĪGA DOMĀŠANA',
      text: 'Mēs esam noenkurojušies pie lielākā un vecākā Latvijas nacionālā parka robežas – Krimuldas pagastā, tāpēc likumsakarīgi, ka domājam tālredzīgi un saimniekojam dabai draudzīgi. Esam ieguvuši Bioloģiski ražotu produktu sertifikātu Nr. 05-001-2012/P-02, izmantojam organiski audzētas izejvielas, kā arī piedāvājam produktus vegāniem.',
    },
  ],
  nature_text:
    'Līdzsvars un harmonija dabā mūs fascinē un veldzē. Iedvesmo jauniem mērķiem un virsotnēm. Daba mūs baro un atjauno. Mēs esam atbildīgi par šo mantojumu un aicinām tādiem būt arī citiem.',
  people_text:
    'Mēs godājam kompetentu, priecīgu un meistarīgu darbinieku mijiedarbībā ar zinošu, pārliecinātu un apmierinātu klientu. Mēs ticam, ka, dzīvojot sabiedrībā, kur cilvēks ir vērtība, mēs atstāsim labāku pasauli saviem bērniem.',
  quality_text:
    'Kvalitāte it visā, kam ķeramies klāt. Ko veidojam un radām. Ikkatrā maizes klaipā un cepuma gabaliņā. Un attiecībās. Kvalitāte ik uz soļa, bez kompromisiem. Tas nebūtu iespējams, ja mēs negodātu šo principu – labāk mazāk, bet augstvērtīgi, nekā pavirši un daudz.',
  create: 'izveidot',
  verify_email: 'Apstipriniet e-pastu',
  verification_instructions:
    'Jums tika nosūtīts e-pasts ar apstiprinājuma kodu.\nIerakstiet kodu lauciņā, lai apstiprinātu e-pasta adresi.',
  password_must: 'Parolei obligāti',
  password_8_chars: 'Jābūt vismaz 8 simbolus garai',
  password_contain_digit: 'Jāsatur ciparu',
  password_contain_special: 'Jāsatur vismaz vienu no !@#$%^&*',
  password_contain_letter: 'Jāsatur burtu',
  toast_all_fields_required: 'Visi lauki ir obligāti',
  toast_address_added: 'Adrese pievienota!',
  toast_sending: 'Tiek sūtīts',
  toast_success: 'Izdevās!',
  toast_failed: 'Neizdevās',
  toast_order_successful: 'Pasūtījums ir saņemts!',
  toast_unauthorized: 'Tev nav piekļuves šai darbībai',
  email_required: 'E-pasts ir obligāts',
  password_required: 'Parole ir obligāta',
  email_already_used: 'Jau eksistē konts ar šādu e-pasta adresi',
  already_have_account: 'Tev jau ir konts? Ienāc šeit',
  dont_have_account: 'Tev vēl nav konta? Reģistrējies šeit',
  invalid_credentials: 'Nepareizs e-pasts vai parole',
  with_VAT: 'Ar PVN',
  expiration_time: 'Derīguma termiņš',
  after_opening: 'Pēc atvēršanas',
  days: 'dienas',
  months: 'mēneši',
  save: 'Saglabāt izmaiņas',
  new: 'Jaunums',
  vegan: 'Vegānisks',
  high_protein: 'Olbaltumvielu avots',
  high_fiber: 'Šķiedrvielu avots',
  handmade: 'Roku darbs',
  no_added_yeast: 'Bez pievienota rauga',
  no_conservants: 'Bez pievienotām E-vielām',
  vitamin_d: 'Vitamīna D avots',
  iodine: 'Joda avots',
  d3: 'vitamīns D3',
  toast_changes_saved: 'Izmaiņas saglabātas!',
  toast_product_created: 'Produkts izveidots!',
  discounts: 'Atlaides',
  found_categories: 'Atrastās kategorijas',
  found_products: 'Atrastie produkti',
  create_discount: 'Izveidot atlaidi',
  remove_discount: 'Noņemt atlaidi',
  discount_price: 'Cena ar atlaidi',
  start_date: 'Sākuma datums',
  end_date: 'Beigu datums',
  without_vat_cents: 'Bez pvn, centos',
  confirm_remove_discount: 'Vai tiešām dzēst šo atlaidi?',
  toast_discount_created: 'Atlaide izveidota!',
  toast_discount_removed: 'Atlaide dzēsta!',
  eu_projects: 'ES Projekti',
  distance_agreement: 'Distances līgums',
  sales: 'Tirdzniecība',
  production: 'Ražošana',
  sales_manager: 'Tirdzniecības vadītājs',
  deputy_sales_manager: 'Tirdzniecības vadītāja vietniece',
  export_manager: 'Eksporta projektu vadītāja',
  bread_manager: 'Maizes ražošanas un kvalitātes vadītāja',
  pastry_manager: 'Konditorejas ražošanas un kvalitātes vadītāja',
  currently_unavailable: 'Pašreiz nav pārdošanā',
  forgot_password: 'Aizmirsi paroli? Spied šeit.',
  reset_password: 'Atjaunot paroli',
  send_reset_instructions: 'Sūtīt atjaunošanas e-pastu',
  toast_reset_email_sent: 'Epasts nosūtīts!',
  confirm_password: 'Parole vēlreiz',
  password_match: 'Parolēm jābūt vienādām',
  email_not_registered: 'Nav konta ar šādu epasta adresi',
  toast_password_reset: 'Parole nomainīta',
  privacy_policy: 'Privātuma politika',
  profile: 'Profils',
  previous_orders: 'Pasūtījumi',
  change_password: 'Mainīt paroli',
  account_information: 'Klienta dati',
  addresses: 'Adreses',
  previous_password: 'Iepriekšējā parole',
  previous_password_required: 'Iepriekšējā parole ir obligāta',
  previous_password_incorrect: 'Iepriekšējā parole ir nepareiza',
  edit: 'Rediģēt',
  delete: 'Dzēst',
  toast_address_edited: 'Adrese saglabāta',
  toast_address_deleted: 'Adrese izdzēsta',
  confirm_delete_address: 'Vai tiešām dzēst šo adresi?',
  save_short: 'Saglabāt',
  when_buying: 'Pērkot',
  or_more: 'vai vairāk',
  delete_account: 'Dzēst kontu',
  confirm_delete_account:
    'Vai tiešām dzēst jūsu kontu? Šī darbība ir neatgriezeniska un jums vairs nebūs piekļuves šim kontam.',
  toast_account_deleted: 'Konts dzēsts',
  cart: 'Grozs',
  delivery: 'Piegāde',
  payment: 'Apmaksa',
  pick_up_from_flora: 'Saņemt maiznīcā',
  next_working_day: 'Nākamajā darbadienā',
  pickup_point: 'DPD pakomātā',
  courrier: 'Ar kurjeru',
  pickup_point_delivery_time: '2 - 3 dienās',
  courrier_delivery_time: '2 - 3 dienās',
  select_point: 'Izvēlies pakomātu',
  to_delivery: 'Uz piegādi',
  to_payment: 'Uz apmaksu',
  toast_select_delivery_method: 'Izvēlieties piegādes metodi!',
  toast_select_pickup_point: 'Izvēlieties pakomātu!',
  toast_select_address: 'Norādi piegādes adresi!',
  to_distance_agreement: 'Distances līgumam',
  agree_terms: 'Esmu izlasījis un piekrītu ',
  pay: 'Apmaksāt',
  toast_must_agree: 'Ir jāpiekrīt distances līgumam, lai veiktu pasūtījumu!',
  related_products: 'Līdzīgi produkti',
  start_over_payment: 'Sākt apmaksu no jauna',
  payment_failed: 'Maksājums neizdevās, lūdzu sāciet no jauna',
  email_verified: 'E-pasts apstiprināts',
  can_start_shopping: 'Jūsu e-pasts ir apstiprināts.\n\nVarat sākt iepirkties!',
  order_completed: 'Pasūtījums saņemts',
  order_completed_info:
    'Mēs esam saņēmuši jūsu pasūtījumu un sāksim to komplektēt.\n\nJa radīsies kādas problēmas, mēs ar jums sazināsimies pa e-pastu\n\nEsam jums nosūtījuši e-pastu ar pirkuma rēķinu',
  search: 'Meklēt',
  image: 'Attēls',
  price_per_one: 'Viena maksā',
  when_buying_x: 'Pērkot x vienības',
  phone_use_info:
    'Jūs saņemsiet visu pasūtījuma informāciju pa e-pastu.\nJa vēlaties, varat norādīt telefona numuru vieglākai saziņai.',
  delivery_bakery: 'Saņemt maiznīcā',
  delivery_courrier: 'Piegāde ar kurjeru',
  delivery_pickupPoint: 'Piegāde uz DPD pakomātu',
  delivery_time_bakery: 'Nākamajā darbadienā',
  delivery_time_courrier: '2 - 7 dienās',
  delivery_time_pickupPoint: '2 - 7 dienās',
  toast_pickup_point_data:
    'Lūdzu ievadiet visu piegādei nepieciešamo infromāciju!',
  price_mismatch:
    'Groza summa nesakrīt ar samaksāto.\nMēs sazināsimies ar jums, lai atrisinātu šo problēmu.',
  toast_price_mismatch: 'Summas nesakrīt',
  to_comments: 'Pievienot komentārus',
  continue: 'Turpināt',
  comments: 'Komentāri',
  businessComments: 'Rekvizīti juridiskiem klientiem',
  businessComments_label: 'Nosaukums, adrese, reģ.nr...',
  generalComments: 'Komentāri maiznīcai',
  generalComments_label: 'Komentārs',
  deliveryComments: 'Komentāri par piegādi',
  deliveryComments_label: 'Komentārs',
  sorting: 'Kārtošana',
  select_category: 'Izvēlies kategoriju',
  select_product: 'Izvēlies produktu',
  banner: 'Plakāts',
  links_to: 'Ved uz ',
  links_to_nowhere: 'Neved nekur',
  enter_captcha: 'Cilvēktests',
  captcha_mismatch: 'Cilvēkpārbaude neizdevās!',
  toast_contact_form_success: 'Ziņa nosūtīta!',
  settings: 'Iestatījumi',
  contact_form_emails: 'Saziņas formas saņēmēji',
  order_notification_emails: 'Pasūtījumu saņēmēji',
  delivery_short_bakery: 'Maiznīcā',
  delivery_short_courrier: 'Ar kurjeru',
  delivery_short_pickupPoint: 'Pakomātā',
  post_index: 'Pasta indekss',
  vat: 'PVN',
  request_courrier: 'Izsaukt kurjeru',
  handed_to_courrier: 'Nodots kurjeram',
  create_shipment: 'Izveidot sūtījumu',
  company_name: 'Nosaukums',
  contact_info: 'Contact info',
  catalogue: 'Katalogs',
  unlisted: 'Neredzami',
  unavailable: 'Nepārdodas',
  uncategorized: 'Nekategorizēti',
  active: 'Aktīvie',
  inactive: 'Neaktīvie',
  new_category: 'Jauna kategorija',
  page_not_found: 'Lapa netika atrasta',
  to_home: 'Uz sākumlapu',
  reason: 'Iemesls',
  delivered: 'Piegādāts',
  picked_up: 'Saņemts',
  confirm_make_completed: 'Pasūtījums ir pabeigts?',
  quantity: 'gab.',
  resend_receipt: 'Nosūtīt rēķinu uz e-pastu',
  special_order:
    'Gatavots pēc pasūtījuma.\nViss pasūtījums tiks sagatavots 10 darbadienu laikā pēc apmaksas saņemšanas.',
  latvia: 'Latvija',
  country: 'Valsts',
  address: 'Adrese',
  regNo: 'Reģ. Nr.',
  pay_now: 'Apmaksāt tagad',
  toast_incomplete_business_comments: 'Nepilnīgi rekvizīti',
  online_payment: 'Apmaksa',
  receipt_payment: 'Apmaksa ar priekšapmaksas rēķinu',
  receive_receipt: 'Saņemt rēķinu',
  receipt_instructions:
    'Nosūtīsim priekšapmaksas rēķinu uz profilā norādīto e-pastu.\nPasūtījuma komplektēšana sāksies tikai pēc apmaksas pabeigšanas.',
  online_payment_instructions: 'Apmaksa ar internetbanku vai maksājumu karti.',
  vatNo: 'PVN reģ. Nr.',
  paid: 'Apmaksāts',
  order_invoiced: 'Rēķins nosūtīts',
  order_invoiced_info:
    'Esam nosūtījuši jums priekšapmaksas rēķinu.\nPasūtījuma komplektēšanu sāksim, kad rēķins būs apmakstāts.\nRēķins ir derīgs 7 dienas no nosūtīšanas brīža.',
  all: 'Visi',
  make_dpd_shipment: 'DPD sūtījums',
  make_own_shipment: 'Pašu sūtījums',
  businessCommentsShort: 'Rekvizīti',
  track: 'Sekot',
  filter: 'Atlasīt',
  depending_on_delivery: 'Atkarīgs no piegādes veida',
  accumulated: 'Uzkrātā',
  money: 'nauda',
  will_receive: 'Saņemsiet',
  money_accusative: 'naudu',
  sum_too_low: 'Kopsumma nevar būt negatīva',
  site_name: 'Maiznīca Flora',
  cert_no: 'Sertifikāta Nr:',
  print: 'Drukāt pavadlapu',
  availability: 'Pieejamība',
  slow_delivery: 'Ilga piegāde',
  invisible_in_search: 'Neredzami meklētājā',
  remove_from_category: 'Izņemt no kategorijas',
  discount_codes: 'Atlaižu kodi',
  discount_code: 'Atlaižu kods',
  discount_code_text: 'Kods',
  discount_code_end_time: 'Beidzas',
  discount_code_percent: 'Procenti (%)',
  confirm_delete: 'Vai tiešām dzēst?',
  toast_discount_code_activated: 'Atlaižu kods aktivizēts',
  code: 'Kods',
  toast_email_verified: 'E-pasts apstiprināts',
  didnt_receive_code: 'Nesaņēmi apstiprinājuma kodu?',
  send_new_code: 'Nosūtīt jaunu kodu',
  toast_code_is_sent: 'Kods nosūtīts',
  verify_email_tab: 'Apstiprināt e-pastu',
  valid_password: '✔ Derīga parole',
  account: 'Konts',
  continue_without_account: 'Turpināt bez konta',
  will_not_receive: '✘ Nesaņemsiet',
  toast_email_missing: 'E-pasts nav norādīts',
  email_explanation: 'E-pasta adrese nepieciešama piegādes veikšanai.',
  and: 'un',
  to_privacy_policy: 'Privātuma politikai',
}

export default lv
