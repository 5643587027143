const de = {
  products: 'produkte',
  about: 'über uns',
  contact: 'kontakt',
  sign_in: 'einloggen',
  sign_up: 'anmelden',
  new_product: 'Neues Produkt',
  management: 'Verwaltung',
  categories: 'Kategorien',
  sign_out: 'Abmelden',
  sum: 'Summe',
  paid_delivery: 'Lieferung',
  under: 'unter €',
  total: 'Gesamt',
  order: 'Bestellung',
  city: 'Stadt',
  street: 'Straße',
  house: 'Haus',
  apt: 'Wohnung',
  name: 'name',
  surname: 'nachname',
  phone: 'Telefon',
  add_new_address: 'neue Adresse hinzufügen',
  proceed_to_payment: 'zur Zahlung gehen',
  contact_us: 'kontaktiere uns',
  email: 'E-Mail',
  message: 'Nachricht',
  send_message: 'Nachricht senden',
  buy: 'kaufen',
  add_to_cart: 'in den Warenkorb legen',
  nutritional_info: 'Nährwertangaben',
  g_contains: '100g enthält',
  energy_content: 'Energiegehalt',
  fat: 'Fett',
  of_which_saturated_fat: 'davon gesättigtes Fett',
  carbohydrates: 'Kohlenhydrate',
  of_which_sugars: 'davon Zucker',
  fiber: 'Ballaststoffe',
  protein: 'Eiweiß',
  salt: 'Salz',
  EAN_code: 'EAN-Code',
  about_us: 'über uns',
  values: 'unsere Werte',
  nature: 'natur',
  people: 'Menschen',
  quality: 'Qualität',
  how_products_are_made: 'WIE WERDEN UNSERE PRODUKTE HERGESTELLT?',
  product: 'Produkt',
  category: 'Kategorie',
  select_existing_categories: 'Vorhandene Kategorien auswählen',
  select_existing_products: 'Vorhandene Produkte auswählen',
  add_category: 'Eine Kategorie hinzufügen',
  add_new_category: 'Eine neue Kategorie hinzufügen',
  product_name: 'Name',
  image_url: 'image url',
  cancel: 'abbrechen',
  add: 'hinzufügen',
  or: 'oder',
  add_new_product: 'Ein neues Produkt hinzufügen',
  add_product: 'Ein Produkt hinzufügen',
  description: 'beschreibung',
  ingredients: 'Zutaten',
  weight: 'Gewicht',
  in_grams: 'in Gramm',
  price: 'Preis (Cent)',
  in_cents: 'in Cent',
  add_to_all: "Zur Kategorie 'alle' hinzufügen",
  add_to_new: "Zur Kategorie 'new' hinzufügen",
  accept_order: 'Bestellung akzeptieren?',
  accept: 'akzeptieren',
  refuse: 'ablehnen',
  ready_for_delivery: 'Bereit zur Lieferung?',
  ready: 'bereit',
  out_for_delivery: 'Zur Lieferung bereit?',
  deliver: 'liefern',
  delivery_completed: 'Lieferung komplett?',
  completed: 'komplett',
  order_status: {
    placed: 'Platziert',
    ready_for_pickup: 'Bereit',
    ready_for_delivery: 'Lieferbar',
    waiting_for_courrier: 'Auf Kurier wartend',
    delivering: 'Ausliefern',
    completed: 'Abgeschlossen',
    invoiced: 'Fakturiert',
    refunded: 'Zurückerstattet',
    dropped_in_pickup_point: 'Abgesetzt im Abholpunkt',
    picked_up_by_courrier: 'Vom Kurier abgeholt',
    delivered_to_pickup_point: 'Geliefert an den Abholpunkt',
  },
  last_modified_by: 'zuletzt geändert von',
  confirm_delete_product:
    'Sind Sie sicher, dass Sie dieses Produkt löschen wollen?',
  confirm_save_changes:
    'Sind Sie sicher, dass Sie diese Änderungen speichern wollen?',
  save_changes: 'Änderungen speichern',
  discard_changes: 'Änderungen verwerfen',
  edit_product: 'Produkt bearbeiten',
  delete_product: 'Produkt löschen',
  empty_cart: 'Ihr Warenkorb ist leer.',
  orders: 'Bestellungen',
  username: 'benutzername',
  password: 'passwort',
  about_text: [
    'Die Tätigkeit der Bäckerei „Flora“ begann schon in den 90er Jahren, als zu den Weihnachten der erste selbstgebackene Weißbrotlaib auf den Tisch gelegt wurde. Der Name der Bäckerei stammt aus der früheren Geschäftstätigkeit der Inhaber, die sich vor der Herstellung von leckeren Backwaren mit der Blumenzucht beschäftigt haben. So entstand die Idee, dem Unternehmen einen mit der Natur verbundenen Namen zu geben, und die Bäckerei wurde „Flora“ genannt.',
    '2008 eröffnete die Bäckerei „Flora“ ihre neue, moderne, den Standards der EU entsprechende Produktionsstätte in der Gemeinde Krimulda – an einem mythischen Ort mit dem Namen Ragana (deutsch: Hexe). Hier werden alle Produktarten hergestellt. Jedoch hat auch in der modernen Produktion nach wie vor den größten Stellenwert die Handarbeit gelegt, die mit den jahrelang gesammelten Kenntnissen über die Herstellung der besten Produkte vereinigt wird.',
    'Im April 2012 hat die Bäckerei „Flora“ das Zertifikat zur Herstellung von BIO-Produkten erhalten.',
    'Flora – es ist die ganze unsere Umwelt, die schön, grün, wohlriechend, echt und natürlich ist. Es sind die sonnigen Blumenwiesen Lettlands mit prachtvollem, rotem Mohn und goldenen Getreideähren. Die Natur und ihre Farben sind unsere Inspirationsquellen, die eine wichtige Rolle bei der Entstehung und der Arbeit der Bäckerei „Flora“ spielen. Ähnlich, wie ein Parfümeur die bezauberndsten und reizvollsten Blumengeruche wählt, um ein neues Aroma zu entwickeln, suchen wir die hochwertigsten Rohstoffe und einzigartigsten Zutaten für unsere Backwaren aus. Dank den Kenntnissen und der sorgfältigen Handarbeit unserer Bäcker entstehen die leckeren Produkte der Bäckerei „Flora“! Wir erfreuen unsere Kunden durch einzigartiges Geschmacksvergnügen, das hier in Lettland, in der malerischen Gemeinde Krimulda entstanden ist. Damit die leckeren Backwaren auf Ihrem Tisch das Gefühl der blühenden Wiesen in Ihr Haus bringen!',
  ],
  create: 'erstellen',
  verify_email: 'E-Mail bestätigen',
  verification_instructions:
    'Öffnen Sie Ihre E-Mail und befolgen Sie die Anweisungen, um Ihre E-Mail-Adresse zu bestätigen und mit dem Einkaufen zu beginnen',
  password_must: 'Passwort muss',
  password_8_chars: 'Mindestens 8 Zeichen lang sein',
  password_contain_digit: 'Mindestens eine Ziffer enthalten',
  password_contain_special: 'Mindestens eines von !@#$%^&* enthalten',
  password_contain_letter: 'Mindestens einen Buchstaben enthalten',
  toast_all_fields_required: 'Alle Felder sind erforderlich',
  toast_address_added: 'Die Adresse wurde hinzugefügt',
  toast_sending: 'Werden gesendet',
  toast_success: 'War erfolgreich!',
  toast_failed: 'Ausfall',
  toast_order_successful: 'Ihre Bestellung wurde aufgegeben!',
  toast_unauthorized: 'Dazu sind Sie nicht berechtigt',
  email_required: 'E-Mail ist erforderlich',
  password_required: 'Passwort ist erforderlich',
  email_already_used: 'Diese E-Mail ist bereits registriert',
  already_have_account: 'Sie haben bereits ein Konto? Einloggen.',
  dont_have_account: 'Sie haben noch kein Konto? Anmelden.',
  invalid_credentials: 'E-Mail oder Passwort ist falsch',
  with_VAT: 'Inkl. MwSt.',
  expiration_time: 'Produkt ist gut für',
  after_opening: 'Nach dem Öffnen gut für',
  days: 'Tage',
  months: 'Monate',
  save: 'Änderungen speichern',
  new: 'Neues Produkt',
  vegan: 'Vegan',
  high_protein: 'Hoher Proteingehalt',
  high_fiber: 'Hoher Anteil an Ballaststoffen',
  handmade: 'Handgemacht',
  no_added_yeast: 'Ohne Zusatz von Hefe',
  no_conservants: 'Ohne Konservierungsstoffe',
  vitamin_d: 'Quelle für Vitamin D',
  iodine: 'Quelle für Jod',
  d3: 'Vitamin D3',
  toast_changes_saved: 'Änderungen wurden gespeichert!',
  toast_product_created: 'Das Produkt wurde erstellt!',
  discounts: 'Rabatte',
  found_categories: 'Gefundene Kategorien',
  found_products: 'Gefundene Produkte',
  create_discount: 'Rabatt erstellen',
  remove_discount: 'Rabatt entfernen',
  discount_price: 'Rabattierter Preis',
  start_date: 'Startdatum',
  end_date: 'Enddatum',
  without_vat_cents: 'ohne Mehrwertsteuer, in Cents',
  confirm_remove_discount:
    'Sind Sie sicher, dass Sie den Rabatt entfernen möchten?',
  toast_discount_created: 'Der Rabatt wurde erstellt!',
  toast_discount_removed: 'Der Rabatt wurde entfernt!',
  eu_projects: 'EU Projekte',
  sales: 'Verkauf',
  production: 'Produktion',
  distance_agreement: 'Entfernungsvereinbarung',
  sales_manager: 'Verkaufsleiter',
  deputy_sales_manager: 'Stellvertretender Verkaufsleiter',
  export_manager: 'Leiter der Exportabteilung',
  bread_manager: 'Leiter der Brotproduktion und -qualität',
  pastry_manager: 'Leiter der Backwarenproduktion und -qualität',
  currently_unavailable: 'Derzeit nicht verfügbar',
  forgot_password: 'Haben Sie Ihr Passwort vergessen?',
  reset_password: 'Passwort zurücksetzen',
  send_reset_instructions: 'E-Mail senden',
  toast_reset_email_sent:
    'E-Mail zum Zurücksetzen des Kennworts wurde gesendet!',
  confirm_password: 'Passwort bestätigen',
  password_match: 'Passwörter müssen übereinstimmen',
  email_not_registered: 'Mit dieser E-Mail-Adresse ist kein Konto verbunden',
  toast_password_reset: 'Das Passwort wurde geändert',
  privacy_policy: 'Datenschutzbestimmungen',
  profile: 'Konto',
  previous_orders: 'Bestellungen',
  change_password: 'Passwort ändern',
  account_information: 'Meine Info.',
  addresses: 'Adressen',
  previous_password: 'Vorheriges Passwort',
  vorheriges_passwort_erforderlich: 'Vorheriges Passwort ist erforderlich',
  previous_password_incorrect: 'Vorheriges Passwort ist falsch',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  toast_address_edited: 'Die Adresse wurde bearbeitet',
  toast_address_deleted: 'Die Adresse wurde gelöscht',
  confirm_delete_address:
    'Sind Sie sicher, dass Sie diese Adresse löschen wollen?',
  save_short: 'Speichern',
  when_buying: 'Wenn',
  or_more: 'oder mehr',
  delete_account: 'Konto löschen',
  confirm_delete_account:
    'Sind Sie sicher, dass Sie Ihr Konto löschen wollen? Der Vorgang ist nicht rückgängig zu machen, und Sie werden nicht mehr auf Ihr Konto zugreifen können.',
  toast_account_deleted: 'Das Konto wurde gelöscht',
  cart: 'Warenkorb',
  delivery: 'Lieferung',
  payment: 'Zahlung',
  pick_up_from_flora: 'Abholung in der Bäckerei',
  next_working_day: 'Nächster Werktag',
  pickup_point: 'DPD Abholpunkt',
  courrier: 'Kurierzustellung',
  pickup_point_delivery_time: '2 - 3 Tage',
  courrier_delivery_time: '2 - 3 Tage',
  select_point: 'Abholort wählen',
  to_delivery: 'Lieferung wählen',
  to_payment: 'Zur Zahlung gehen',
  toast_select_delivery_method: 'Wählen Sie eine Liefermethode!',
  toast_select_pickup_point: 'Wählen Sie einen Abholpunkt!',
  toast_select_address: 'Wählen Sie eine Adresse!',
  to_distance_agreement: 'Fernabsatzvertrag',
  agree_terms: 'Ich habe die Bedingungen gelesen und stimme ihnen zu ',
  pay: 'Bezahlen',
  toast_must_agree:
    'Sie müssen mit den Bedingungen einverstanden sein, um die Bestellung aufzugeben!',
  related_products: 'Verwandte Produkte',
  start_over_payment: 'Mit der Zahlung neu beginnen',
  payment_failed: 'Zahlung fehlgeschlagen, bitte von vorne beginnen',
  email_verifiziert: 'E-Mail verifiziert',
  can_start_shopping:
    'Ihre E-Mail wurde verifiziert.\n\nSie können jetzt mit dem Einkaufen beginnen!',
  order_completed: 'Bestellung abgeschlossen',
  order_completed_info:
    'Wir haben Ihre Bestellung erhalten und werden in Kürze mit dem Verpacken beginnen.\n\nWenn es irgendwelche Probleme gibt, werden wir Sie per E-Mail kontaktieren.\n\nWir haben Ihnen auch eine E-Mail mit der Quittung für diese Bestellung geschickt.',
  search: 'Suche',
  image: 'Bild',
  price_per_one: 'Einer kostet',
  when_buying_x: 'Beim Kauf von x',
  phone_use_info:
    'Sie erhalten alle notwendigen Informationen per E-Mail.\nSie können optional Ihre Telefonnummer für eine einfachere Kommunikation angeben.',
  delivery_bakery: 'Abholung in der Bäckerei',
  delivery_courrier: 'DPD Kurierzustellung',
  delivery_pickupPoint: 'DPD Abholstelle',
  delivery_time_bakery: 'Nächster Werktag',
  delivery_time_courrier: '2 - 7 Tage',
  delivery_time_pickupPoint: '2 - 7 Tage',
  toast_pickup_point_data:
    'Bitte geben Sie alle erforderlichen Daten für die Lieferung am Abholpunkt ein',
  price_mismatch:
    'Es gab eine Diskrepanz zwischen dem Gesamtbetrag des Warenkorbs und dem bezahlten Betrag.\nWir werden Sie per E-Mail kontaktieren, um das Problem zu lösen.',
  toast_price_mismatch: 'Problem mit bezahltem Betrag',
  to_comments: 'Kommentare hinzufügen',
  continue: 'Weiter',
  comments: 'Kommentare',
  businessComments: 'Geschäftsangaben für Firmenkunden',
  businessComments_label: 'Name, Adresse, ID...',
  generalComments: 'Kommentare für die Bäckerei Flora',
  generalComments_label: 'Kommentar',
  deliveryComments: 'Kommentare zur Lieferung',
  LieferungKommentare_label: 'Kommentar',
  sorting: 'Sortierung',
  select_category: 'Wählen Sie eine Kategorie',
  select_product: 'Wählen Sie ein Produkt',
  banner: 'Banner',
  links_to: 'Links zu ',
  links_to_nowhere: 'Kein Link',
  enter_captcha: 'Captcha',
  captcha_mismatch: 'Captcha falsch',
  toast_contact_form_success: 'Nachricht gesendet!',
  settings: 'Einstellungen',
  contact_form_emails: 'Kontakt-Formular-Empfänger',
  order_notification_emails: 'Empfänger von Bestellungen',
  delivery_short_bakery: 'Bäckerei',
  delivery_short_courrier: 'Kurierdienst',
  delivery_short_pickupPoint: 'Abholstelle',
  post_index: 'Postleitzahl',
  vat: 'MWT',
  request_courrier: 'Kurierdienst anfordern',
  handed_to_courrier: 'Dem Kurier übergeben',
  create_shipment: 'Sendung erstellen',
  company_name: 'Firmenname',
  contact_info: 'Kontaktinformationen',
  catalogue: 'Katalog',
  unlisted: 'Unsichtbar',
  unavailable: 'Nicht verfügbar',
  uncategorized: 'Nicht kategorisiert',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  new_category: 'Neue Kategorie',
  page_not_found: 'Seite nicht gefunden',
  to_home: 'Zur Homepage',
  reason: 'Grund',
  delivered: 'Geliefert',
  picked_up: 'Abgeholt',
  confirm_make_completed: 'Ist die Bestellung abgeschlossen?',
  quantity: 'Stk.',
  resend_receipt: 'Erneut per E-Mail senden',
  special_order:
    'Wird auf Anfrage erstellt.\nDie Bestellung wird innerhalb von 10 Arbeitstagen ausgeführt.',
  latvia: 'Lettland',
  country: 'Land',
  Adresse: 'Adresse',
  regNo: 'Reg. Nr.',
  pay_now: 'Jetzt bezahlen',
  toast_incomplete_business_comments: 'Geschäftsangaben unvollständig',
  online_payment: 'Zahlung',
  receipt_payment: 'Rechnungszahlung',
  receive_receipt: 'Rechnung erhalten',
  receipt_instructions:
    'Eine Rechnung wird an Ihre E-Mail gesendet.\nDas Verpacken beginnt erst nach Zahlungseingang.',
  online_payment_instructions: 'Zahlung mit Onlinebanking oder Zahlungskarte',
  latvia_delivery: 'Derzeit wird nur in Lettland geliefert.',
  vatNo: 'USt-IdNr.',
  paid: 'Bezahlt',
  order_invoiced: 'Rechnung gesendet',
  order_invoiced_info:
    'Wir haben Ihnen eine Rechnung geschickt.\nWir beginnen mit dem Verpacken der Bestellung, sobald die Zahlung erfolgt ist.\nDie Rechnung ist 7 Tage lang gültig.',
  all: 'Alle',
  make_dpd_shipment: 'DPD-Sendung',
  make_own_shipment: 'Eigene Sendung',
  businessCommentsShort: 'Geschäftliche Details',
  track: 'Verfolgen',
  filter: 'Filtern',
  depending_on_delivery: 'Abhängig von der Liefermethode',
  accumulated: 'Verwenden',
  money: 'Geld',
  will_receive: 'Empfangen',
  money_accusative: 'Geld',
  sum_too_low: 'Die Summe kann nicht negativ sein',
  site_name: 'Bäckerei Flora',
  cert_no: 'Zertifikat Nr:',
  print: 'Versandetikett drucken',
  availability: 'Verfügbarkeit',
  slow_delivery: 'Langsame Lieferung',
  invisible_in_search: 'Nicht durchsuchbar',
  remove_from_category: 'Aus Kategorie entfernen',
  discount_codes: 'Rabattcodes',
  discount_code: 'Rabattcode',
  discount_code_text: 'Code',
  discount_code_end_time: 'Verfall',
  discount_code_percent: 'Prozentsatz (%)',
  confirm_delete: 'Löschung bestätigen',
  toast_discount_code_activated: 'Rabattcode aktiviert',
  code: 'Code',
  toast_email_verified: 'E-Mail verifiziert',
  didt_receive_code: 'Keinen Bestätigungscode erhalten?',
  send_new_code: 'Neuen Code senden',
  toast_code_is_sent: 'Code gesendet!',
  verify_email_tab: 'E-Mail bestätigen',
  valid_password: '✔ Gültiges Passwort',
  account: 'Konto',
  continue_without_account: 'Ohne Konto fortfahren',
  will_not_receive: '✘ Sie werden nicht empfangen',
  toast_email_missing: 'Keine E-Mail angegeben',
  email_explanation: 'E-Mail-Adresse ist für die Zustellung erforderlich',
  and: 'und',
  to_privacy_policy: 'Datenschutzbestimmungen',
}

export default de
